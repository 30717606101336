import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "./../../contexts/app.context";
import { getFieldValue, Link } from "@sitecore-jss/sitecore-jss-react";
import {
  DELIMITER_COLON,
  DEPENDANT_18AndOVER,
  ICON_PATH,
  SUBSCRIBER,
  DEPENDANT_ACCESS_GRANTED_ROLEID,
  PRIMARY_SUBSCRIBER_ROLEID,
  NM,
  KY,
  MARKETPLACE,
  MEDICAID,
  NY,
  NY_MD_CHP_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  MEDICARE,
  NYMCC_PROGRAMID,
  NY_MD_SWHFIDE_PROGRAMID,
  NY_MC_SWH_PROGRAMID,
  SC_MEDICAID,
  SC,
  MA,
} from "../../define.constants";
import NavLink from "./NavLink";
import Logout from "../Logout";
import {
  GLOBAL_VARIABLE,
  IL,
  CA,
  CA_PROGID_LIST_HIDE_MYDOC,
  IL_MLTSS_PROGRAM,
  SWH_PROGRAMS,
} from "../../define.constants";
import { withModalProvider } from "../../hoc/withModalProvider";
import { brightCarePortal, isNYMDCHP } from "../../utils/helpers";

function LeftNavigation(props) {
  let {
    memberFirstName,
    unreadInboxMsgCount,
    id,
    Dependents,
    isDependentLoggedIn,
    isSwitchedView,
    switchedUserType,
    switchedUserDependentCat,
    programId,
    selectedMemberNumber,
    roleId,
    isSwitchedToDependent,
    MemberStateName,
    memberLOB,
    isNYAffinityMember,
  } = useContext(AppContext);

  const { fields, closeNav, onMyBillClick } = props;
  const [tabshow, settabshow] = useState(true);
  const location = useLocation();

  const [isBrightCare, setIsBrightCare] = useState(null);

  useEffect(async () => {
    setIsBrightCare(await brightCarePortal());
  }, []);

  const isSCMedicaid =
    memberLOB?.toLowerCase() === MEDICAID &&
    MemberStateName === SC &&
    programId === SC_MEDICAID;

  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;
  const isNYMLTCProgram =
    MemberStateName === NY &&
    memberLOB?.toLowerCase() === MEDICAID &&
    programId === NYMCC_PROGRAMID;
  const isNYChipMedicaid =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === NY &&
    programId === NY_MD_CHP_PROGRAMID;
  const isNYSWHFIDEMedicaid =
    MemberStateName === NY &&
    memberLOB?.toLowerCase() === MEDICAID &&
    programId === NY_MD_SWHFIDE_PROGRAMID;
  const isNYSWHMedicare =
    MemberStateName === NY &&
    memberLOB?.toLowerCase() === MEDICARE &&
    programId === NY_MC_SWH_PROGRAMID;
  const isSWHMember = SWH_PROGRAMS.includes(programId);
  const isMASWHMember = isSWHMember && MemberStateName === MA;
  const program = () => {
    const programIdName = [];
    var result = getFieldValue(fields, "MyDoctor", false).title;
    if (result && result !== null) {
      var programObject = result.split("~");
      if (programObject?.length > 1) {
        var programnames = programObject[1].includes(";")
          ? [programObject[1].split(";")]
          : [programObject[1]];
        programnames?.map((item) => {
          if (item === programId) {
            if (!programIdName.includes(item)) {
              programIdName.push(item);
            }
          }
        });
        if (programIdName.length > 0) {
          settabshow(false);
        }
      }
    }
  };
  const SCMedicaidBranding = () => {
    return (
      <img
        src={`${ICON_PATH}/SC_Molina_Healthy_Connections.jpg`}
        alt="SC Molina Healthcare Healthy Connections"
        className="SClogoMrgnLeft"
      />
    );
  };
  const healthRecordVisibility = () => {
    if (
      (isDependentLoggedIn &&
        isSwitchedView &&
        switchedUserType === SUBSCRIBER) || //Conditions for 1486 & 1490
      (!isDependentLoggedIn &&
        isSwitchedView &&
        switchedUserDependentCat === DEPENDANT_18AndOVER) //Conditions for 1489
    ) {
      return false;
    } else {
      return true;
    }
  };
  const isMyBillDisabled = () => {
    if (
      roleId === PRIMARY_SUBSCRIBER_ROLEID ||
      roleId == DEPENDANT_ACCESS_GRANTED_ROLEID ||
      isSwitchedToDependent === true ||
      isNYChipMedicaid
    ) {
      return false;
    } else {
      return true;
    }
  };
  const urlDisabled = isMyBillDisabled();
  const PageLink = ({ className, pageHref, pageText, closeNav }) => {
    if (pageHref === `${location.pathname}${location.hash}`) {
      return (
        <a href={pageHref} className={className} onClick={closeNav}>
          {pageText}
        </a>
      );
    }

    return (
      <a href={pageHref} className={className}>
        {pageText}
      </a>
    );
  };

  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID?.toLowerCase();
  /* Medthod returning flag to Hide MyDoctor for
  IL - MLTSS Medicaid
  CA - EAE Medicaid
  CA - MMP Opt Out & Dual Opt Out converted to Medicaid ProgIDs 
  */
  const showMyDoctor = () => {
    let showMyDoctorFlag = true;
    // Medicaid Common check
    const isMedicaidCommonCheck = isMedicaidMember && programId;
    const trimmedProgramID = programId?.trim();

    // State & ProgramID wise check to hide MyDoctor
    if (isMedicaidCommonCheck) {
      switch (MemberStateName.trim()) {
        case CA:
          showMyDoctorFlag = CA_PROGID_LIST_HIDE_MYDOC.includes(
            trimmedProgramID
          )
            ? false
            : true;
          break;
        case IL:
          showMyDoctorFlag =
            trimmedProgramID === IL_MLTSS_PROGRAM ? false : true;
          break;
        default:
          showMyDoctorFlag = true;
      }
    }

    return showMyDoctorFlag;
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white ">
        <div className="sidenav-btn">
          <a href="#" className="menubtn" onClick={closeNav}>
            <img
              src={`${ICON_PATH}/hamburger-menu.svg`}
              className="header-icon"
              alt={getFieldValue(fields, "menuIconAltText", false)}
            />
          </a>
          <a href="#" className="closebtn" onClick={closeNav}>
            <img
              src={`${ICON_PATH}/letter-x.svg`}
              alt={getFieldValue(fields, "closeIconAltText", false)}
            />
          </a>
        </div>
      </nav>
      <div className={MemberStateName === KY ? "nav-KY" : ""}>
        <ul className="sidenav-nav">
          <li className="sidenav-nav-logo">
            <Link
              className="molina-leftnav-logo navbar-center d-flex"
              field={GLOBAL_VARIABLE.lnkHome}
            >
              {isBrightCare != null ? (
                <>
                  {isBrightCare ? (
                    <>
                      <img
                        src={`${ICON_PATH}/Central_HealthPlan_logo_320.svg`}
                        className={"logo h-auto"}
                        alt={getFieldValue(fields, "logoIconAltText", false)}
                      />
                      <span>{getFieldValue(fields, "lblMyCHP", false)}</span>
                    </>
                  ) : (
                    <>
                      <img
                        src={`${ICON_PATH}/${
                          MemberStateName === KY
                            ? "logo-passport.svg"
                            : MemberStateName === NY &&
                              isNYAffinityMember === true
                            ? "Affinity_Molina_Healthcare_logo.png"
                            : isNYSWHMedicare ||
                              isNYSWHFIDEMedicaid ||
                              isNYMLTCProgram
                            ? "Molina_Healthcare_SWH_logo.png"
                            : isMASWHMember
                            ? "SWH_MA_Logo.png"
                            : "Molina_Healthcare_Logo_320.svg"
                        }`}
                        className={
                          MemberStateName === NY && isNYAffinityMember === true
                            ? "Affinity_logo_hambergur"
                            : "logo"
                        }
                        alt={getFieldValue(fields, "logoIconAltText", false)}
                      />

                      {MemberStateName === NY && isNYAffinityMember === true ? (
                        <span>
                          {getFieldValue(fields, "lblNYAffinity", false)}
                        </span>
                      ) : isNYSWHMedicare || isMASWHMember ? (
                        <span className="NY_MSWH">
                          {getFieldValue(fields, "lblSWH", false)}
                        </span>
                      ) : isNYMLTCProgram || isNYSWHFIDEMedicaid ? (
                        <span className="NY_MSWH">
                          {getFieldValue(fields, "lblNYMEdicaidSWH", false)}
                        </span>
                      ) : (
                        <span>
                          {getFieldValue(fields, "lblMyMolina", false)}
                        </span>
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </Link>
            {isSCMedicaid && SCMedicaidBranding()}
          </li>
          <li className="sidenav-nav-title">
            <span>
              {getFieldValue(fields, "lblLeftWelcome", false)}
              {memberFirstName}
            </span>
          </li>
          <li className="sidenav-nav-title">
            {getFieldValue(fields, "lblLeftID", false)}
            {id}
          </li>
          {isMarketplaceMember && Dependents?.length > 0 && (
            <>
              {Dependents?.map((item, index) => {
                if (item.MemberNumber !== selectedMemberNumber) {
                  return (
                    <li className="sidenav-nav-title" key={index}>
                      <span className="leftnav-label">
                        {item.IsCurrentLogin
                          ? `${item.RelationshipToLoggedInUser}${DELIMITER_COLON}`
                          : `${item.FirstName} ${item.LastName}${DELIMITER_COLON}`.toLowerCase()}
                      </span>
                      <span className="leftnav-value">
                        {getFieldValue(fields, "lblID", false)}
                        {item?.MemberNumber}
                      </span>
                    </li>
                  );
                }
              })}
            </>
          )}
        </ul>
        <hr className="dropdown-divider" />
        <ul className="sidenav-nav">
          {!urlDisabled &&
            ((getFieldValue(fields, "MyBill", false)?.href &&
              !getFieldValue(fields, "MyBill", false)?.programId) ||
              (getFieldValue(fields, "MyBill", false)?.href &&
                getFieldValue(fields, "MyBill", false)?.programId?.includes(
                  programId
                ))) && (
              <>
                <li>
                  <NavLink
                    onClick={onMyBillClick}
                    className={`cursor-pointer nav-link ${
                      urlDisabled ? "disabled-link" : ""
                    }`.trim()}
                  >
                    {getFieldValue(fields, "MyBill", false)?.text}
                  </NavLink>
                </li>
              </>
            )}
          <li>
            <PageLink
              className="nav-link"
              pageHref={getFieldValue(fields, "MyCoverage", false)?.href}
              pageText={getFieldValue(fields, "MyCoverage", false)?.text}
            />
          </li>
          <li>
            <PageLink
              className="nav-link"
              pageHref={getFieldValue(fields, "MyIDCard", false)?.href}
              pageText={getFieldValue(fields, "MyIDCard", false).text}
            />
          </li>
          {tabshow && showMyDoctor() && (
            <>
              {program()}
              <li>
                <PageLink
                  className="nav-link"
                  pageHref={getFieldValue(fields, "MyDoctor", false)?.href}
                  pageText={getFieldValue(fields, "MyDoctor", false)?.text}
                />
              </li>
            </>
          )}
          {healthRecordVisibility() && (
            <li>
              <PageLink
                className="nav-link"
                pageHref={getFieldValue(fields, "MyHealthRecords", false)?.href}
                pageText={getFieldValue(fields, "MyHealthRecords", false)?.text}
              />
            </li>
          )}
          <li>
            <PageLink
              className="nav-link"
              pageHref={getFieldValue(fields, "MyResources", false)?.href}
              pageText={getFieldValue(fields, "MyResources", false)?.text}
            />
          </li>
          <li>
            <PageLink
              className="nav-link"
              pageHref={getFieldValue(fields, "MyWellness", false)?.href}
              pageText={getFieldValue(fields, "MyWellness", false)?.text}
            />
          </li>
          {fields && fields["RxSS"] ? (
            fields["RxSS"].value?.href !== "" ? (
              <li>
                <PageLink
                  className="nav-link"
                  pageHref={getFieldValue(fields, "RxSS", false)?.href}
                  pageText={getFieldValue(fields, "RxSS", false)?.text}
                />
              </li>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </ul>
        <hr className="dropdown-divider" />
        <ul className="sidenav-nav">
          <li>
            <div className="nav-link">
              <a
                href={
                  getFieldValue(fields, "lnkLeftNotifications", false)?.href
                }
              >
                {getFieldValue(fields, "lnkLeftNotifications", false)?.text}
              </a>
              {unreadInboxMsgCount > 0 ? (
                <span className="notification-unseen-flag"></span>
              ) : (
                <span></span>
              )}
            </div>
          </li>

          <li>
            <PageLink
              className="nav-link"
              pageHref={getFieldValue(fields, "lnkLeftContact", false)?.href}
              pageText={getFieldValue(fields, "lnkLeftContact", false)?.text}
            />
          </li>

          <li>
            <PageLink
              className="nav-link"
              pageHref={getFieldValue(fields, "lnkLeftLanguages", false)?.href}
              pageText={getFieldValue(fields, "lnkLeftLanguages", false)?.text}
              closeNav={closeNav}
            />
          </li>
          <li>
            <PageLink
              className="nav-link"
              pageHref={
                getFieldValue(fields, "lnkAccountSettings", false)?.href
              }
              pageText={
                getFieldValue(fields, "lnkAccountSettings", false)?.text
              }
            />
          </li>
          <li>
            <Logout fields={fields} postition="left" />
          </li>
        </ul>
        <div className="sidenav-bg rgba-blue-strong"></div>
      </div>
    </>
  );
}
export default withModalProvider(LeftNavigation);

import React, { useContext, useState } from "react";
import {
  Text,
  RichText,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { getStateConfiguration } from "./../../utils/helpers";
import { AppContext } from "../../contexts/app.context";
import { SC, MEDICAID } from "../../define.constants";

const displayAccordionItems = (list, expandedIndex, setExpandedIndex) => {
  return (
    <div className="accordion" id="PregnancyandYourBabyAccordion">
      {Object.keys(list).map((key, index) => {
        const isExpanded = expandedIndex === index;

        return (
          <div className="accordion-item cards-tab3" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className={`accordion-button ${isExpanded ? "" : "collapsed"}`}
                type="button"
                onClick={
                  () => setExpandedIndex(isExpanded ? null : index) // Toggle the accordion
                }
                aria-expanded={isExpanded}
                aria-controls={`collapse${index}`}
              >
                <Text field={list[key]?.fields?.accordionHeading} />
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse ${
                isExpanded ? "expanded" : "collapsed"
              }`}
              aria-labelledby={`heading${index}`}
            >
              {isExpanded && (
                <div className="accordion-body">
                  <RichText field={list[key]?.fields?.accordionDescription} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const MyResourcesPregnancyandYourBaby = (props) => {
  const [expandedIndex, setExpandedIndex] = useState(null); // State to track the expanded accordion item
  let fields = props?.fields;
  let { MemberStateName, memberLOB } = useContext(AppContext);
  let list = getStateConfiguration(props.fields, MemberStateName, memberLOB);

  let isSCMedicaidMember = false;
  // MEDICAID Check- SC
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  isSCMedicaidMember =
    isMedicaidMember && MemberStateName?.toUpperCase() === SC;

  return isSCMedicaidMember ? (
    <>
      <br />
      <div className="font-mac-items">
        <RichText field={fields?.description1} />
      </div>
      <br />
      <div className="row">
        &nbsp;
        {displayAccordionItems(list, expandedIndex, setExpandedIndex)}
      </div>
    </>
  ) : (
    ""
  );
};

export default MyResourcesPregnancyandYourBaby;
